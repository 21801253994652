@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-ExtraBold.woff2') format('woff2'),
        url('Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

